import Masonry1 from "../Pictures/MasonryPics/Masonry-1.jpg"
import Masonry2 from "../Pictures/MasonryPics/Masonry-2.jpg"
import Masonry3 from "../Pictures/MasonryPics/Masonry-3.jpg"
import Masonry4 from "../Pictures/MasonryPics/Masonry-4.jpg"
import Masonry5 from "../Pictures/MasonryPics/Masonry-5.jpg"
import Masonry6 from "../Pictures/MasonryPics/Masonry-6.jpg"
import Masonry7 from "../Pictures/MasonryPics/Masonry-7.jpg"
import Masonry8 from "../Pictures/MasonryPics/Masonry-8.jpg"
import Masonry9 from "../Pictures/MasonryPics/Masonry-9.jpg"
import Masonry10 from "../Pictures/MasonryPics/Masonry-10.jpg"
import Masonry11 from "../Pictures/MasonryPics/Masonry-11.jpg"
import Masonry12 from "../Pictures/MasonryPics/Masonry-12.jpg"
import Masonry13 from "../Pictures/MasonryPics/Masonry-13.jpg"
import Masonry14 from "../Pictures/MasonryPics/Masonry-14.jpg"
import Masonry15 from "../Pictures/MasonryPics/Masonry-15.jpg"
const MasonryPicData=[
    {
        "image":Masonry1
    },
    {
        "image":Masonry2
    },
    {
        "image":Masonry3
    },
    {
        "image":Masonry4
    },
    {
        "image":Masonry5
    },
    {
        "image":Masonry6
    },
    {
        "image":Masonry7
    },
    {
        "image":Masonry8
    },
    {
        "image":Masonry9
    },
    {
        "image":Masonry10
    },
    {
        "image":Masonry11
    },
    {
        "image":Masonry12
    },
    {
        "image":Masonry13
    },
    {
        "image":Masonry14 
    },
    {
        "image":Masonry15
    },
]
export default MasonryPicData;